.contact-form {
    background: #333;
    color: #ffc107;
    /* Yellow text color */
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    transition: transform 0.5s ease-in-out;
    margin: auto;
}

.contact-form:hover {
    transform: scale(1.05);
}

.contact-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
}

.contact-form input,
.contact-form textarea,
.contact-form button {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    font-size: 14px;
}

.contact-form input:focus,
.contact-form textarea:focus {
    outline: none;
    box-shadow: 0 0 5px #ffc107;
}

.contact-form button {
    background: #ffc107;
    color: #333;
    border: none;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease-in-out;
}

.contact-form button:hover {
    background: #e6a306;
}

.contact-form .message {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
}

@media (max-width: 768px) {
    .contact-form {
        padding: 20px;
    }

    .contact-form h2 {
        font-size: 20px;
    }

    .contact-form input,
    .contact-form textarea,
    .contact-form button {
        padding: 10px;
    }
}

.page-header {
    text-align: center;
    margin-bottom: 40px;
}

.page-header h1 {
    font-size: 2.5rem;
}