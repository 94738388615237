.carousel-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
    z-index: 5;
}

.carousel {
    width: 90%;
    height: 90%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: red; */
}

.carousel-file {
    width: max-content;
    max-width: 600px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slick-list,
.slick-track,
.slick-slide div {
    height: 100%;
}

.slick-dots li button::before {
    color: white !important;
}

.slick-slider {
    height: 100%;
}