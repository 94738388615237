.actor-card {
    /* width: 350px; */
    /* max-width: 90%; */
    /* border: 1px solid black; */
    border-radius: 10px;
    padding: 10px;
    position: relative;
    /* box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.9); */
    display: flex;
    /* margin: 10px; */
    /* background-image: url("../assets/img/cinema.jpg"); */
    background-position: center;
    background-size: cover;
    z-index: 2;
}

#dp {
    width: 80px;
    height: 80px;
    border-radius: 70px;
    cursor: pointer;
    overflow: hidden;
}

#dp img {
    width: 100%;
    aspect-ratio: 1;
}

#dp:hover {
    transform: scale(1.1);
}

.expanded-img {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    display: none;
    justify-content: center;
    align-items: center;
}

.expanded-img img {
    display: block;
    margin: auto;
    margin-top: 15%;
    width: 250px;
    height: 250px;
    border-radius: 50%;
}

#blur {
    color: transparent;
    text-shadow: 0 0 8px #000;
}

.actor-details {
    flex-grow: 1;
    padding-left: 15px;
}

.actor-card span {
    font-size: medium;
    font-style: italic;
}

.actor-card p {
    font-family: inherit;
    font-size: 15px;
    margin: 5px 0;
}

.actor-card .interests {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1;
    font-size: x-small;
    width: 150px;
    color: rgb(106, 106, 106);
}

.map,
#lock {
    width: 20px;
    height: 20px;
    vertical-align: text-bottom;
}

.actor-card .gender {
    width: 20px;
    color: #fcb603;
}

.actor-card .location-icon {
    color: #fcb603;
}

.prevent-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    opacity: 1;
    z-index: 1;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: transparent;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    /* opacity: 0; */
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip .tooltiptext button {
    background-color: rgba(0, 0, 0, 0.533);
    color: yellow;
    border: none;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin-top: 5px;
    border-radius: 4px;
}

#city {
    padding-top: 10px;
    margin-left: 10px;
}

.skin-tone {
    width: 100px;
    height: 30px;
    border-radius: 5px;
    /* margin-top: 5px; */
    /* border: 1px solid black; */
}

#main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: last baseline;
    width: 70%;
}

.actor-card-checkbox {
    position: absolute;
    z-index: 4;
}