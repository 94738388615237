/* CSS styles */
/* Custom font */
.register-container {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    font-family: "Trebuchet MS", Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    background-image: url('../assets/img/bg2.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    backdrop-filter: blur(3px);
}

/* body {
    background-image: url('../assets/img/bg2.png');
    background-size: cover;
    background-repeat: no-repeat;
    backdrop-filter: blur(3px);
} */

.expanded-img {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    display: none;
    justify-content: center;
    align-items: center;
}

.expanded-img img {
    display: block;
    margin: auto;
    margin-top: 15%;
    width: 250px;
    height: 250px;
    border-radius: 50%;
}

.close-button {
    font-size: large;
    position: absolute;
    background: none;
    border: none;
    right: 40px;
    top: 40px;
}

.gallery-popup .popup-content {
    width: 700px;
    max-width: 90%;
    height: 500px;
    max-height: 90vh;
}

.profile-pic-container {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: relative;
    /* width: 100px;
    height: 100px; */
    /* margin-top: 1%; */
    margin: 10px auto 15px;
    border-radius: 50px;
    /* margin-right: 10px; */
    /* background-image: url("../assets/img/dp.jpg"); */
    /* position: relative; */
    /* left: 30%; */
    transition: transform 0.3s ease;
    cursor: pointer;
}

.profile-pic {
    width: 100%;
    aspect-ratio: 1;

}

/* .rp-controls,
.rp-preview,
.rp-editor {
    width: 100% !important;
    height: 100% !important;
} */

.options-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgb(228, 228, 228);
    font-size: large;
}

.profile-pic-container:hover .options-overlay {
    display: flex;
}

.checkbox-menu li label {
    display: block;
    padding: 3px 10px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    font-size: small;
    white-space: nowrap;
    margin: 0;
    transition: background-color .4s ease;
}

.checkbox-menu li input {
    margin-right: -50px;
    margin-left: -45px;
    top: 2px;
    position: relative;
}

.checkbox-menu li.active label {
    background-color: white;
    font-weight: bold;
}

.checkbox-menu li label:hover,
.checkbox-menu li label:focus {
    background-color: white;
}

.checkbox-menu li.active label:hover,
.checkbox-menu li.active label:focus {
    background-color: white;
}

.dropdown-btn-wrapper {
    text-align: center;
    margin-top: 10px;
}

#msform {
    width: 400px;
    max-width: 90%;
    /* margin: 50px auto; */
    text-align: center;
    position: relative;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#msform fieldset {
    background: white;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
    padding: 20px 30px;
    box-sizing: border-box;
    width: 80%;
    margin: 0 10%;
    position: relative;
}

/* CSS transitions */
fieldset {
    display: none;
    opacity: 0;
    transition: all 2s ease-in-out;
}

fieldset.active {
    display: block;
    opacity: 1;
}


#msform input,
#msform textarea,
#dropdownMenu1 {
    outline: none;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    color: #2C3E50;
    font-size: 13px;
}

#msform input:focus,
#msform textarea:focus,
#dropdownMenu1:focus {
    border-color: #fcb603;
}

#dropdownMenu1 {
    text-align: left;
}

.action-button {
    /* width: 100%; */
    background: #fcb603;
    font-weight: bold;
    color: white !important;
    border: 0 none;
    border-radius: 1px;
    cursor: pointer;
    padding: 10px;
    margin: 10px 0;
    text-decoration: none;
    font-size: 14px;
}

.action-button:hover,
.action-button:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #fcb603;
}

.fs-title {
    font-size: 15px;
    text-transform: uppercase;
    color: #2C3E50;
    margin-bottom: 10px;
}

.fs-subtitle {
    font-weight: normal;
    font-size: 13px;
    color: #666;
    margin-bottom: 20px;
}

#progressbar {
    margin-bottom: 30px;
    padding: 0;
    overflow: hidden;
    counter-reset: step;
}

#progressbar li {
    list-style-type: none;
    color: white;
    text-transform: uppercase;
    font-size: 9px;
    width: 33.33%;
    float: left;
    position: relative;
}

#progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 20px;
    line-height: 20px;
    display: block;
    font-size: 10px;
    color: #333;
    background: white;
    border-radius: 3px;
    margin: 0 auto 5px auto;
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: white;
    position: absolute;
    left: -50%;
    top: 9px;
    z-index: -1;
}

#progressbar li:first-child:after {
    content: none;
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #fcb603;
    color: white;
}

.register-container .gender {
    display: flex;
    justify-content: flex-start;
    font-size: small;
}

.error-message {
    color: red;
}

label img {
    width: 50px;
    height: 50px;
}

.dropzone {
    border: 2px dashed #ccc;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    width: 100%;
    position: relative;
    transition: height 0.3s ease;
    margin-bottom: -30px;
}

.dropzone:hover {
    border-color: #777;
}

.dropzone input {
    display: none;
}

.dropzone label {
    display: block;
    cursor: pointer;
}

.file-list {
    margin-top: 10px;
    text-align: left;
    overflow-y: auto;
    max-height: 100px;
    padding-right: 5px;
}

.file-list-item {
    margin-bottom: 5px;
}

/* MultiForm.css */
.fade-enter {
    opacity: 0;
    transform: translateX(50px);
}

.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 300ms, transform 300ms;
}

.fieldset {
    position: absolute;
    width: 100%;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    width: 60%;
    max-width: 400px;
    text-align: center;
    /* position: absolute; */
    /* top: 30%;
    left: 40%; */
    align-items: center;
}

.blur-background {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
}

#verify {
    margin-top: 0%;
    margin-left: -3%;
}

.button-container {
    /* flex-direction: row; */
    width: 100%;
}

.otp-input {
    width: 35px !important;
    margin-right: 15px;
    text-align: center;
    border: 2px solid rgba(0, 0, 0, .2);
    border-radius: 5px;
    height: 40px;
    outline: none;
}

.react-select {
    margin-bottom: 10px;
}

.otp-container {
    width: fit-content;
    margin: 0 auto;
}

.resend-link {
    color: rgb(44, 100, 212) !important;
    background: none;
    border: none;
    font-size: x-small;
}


.CardField {
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;

    font-size: 13px;
    color: #2C3E50;
}