.service-card {
    background-color: #ffd700;
    /* Yellow background */
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
    height: 100%;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.service-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #ff7e5f, #feb47b);
    z-index: -1;
    transition: all 0.3s ease;
}

.service-card:hover::before {
    opacity: 0.8;
}

.service-card .card-body {
    padding: 30px;
}

.service-card .card-title {
    color: #000;
    /* Black text */
    font-size: 22px;
    margin-bottom: 15px;
}

.service-card .card-text {
    color: #000;
    /* Black text */
    font-size: 16px;
    line-height: 1.6;
}

.attractive-button {
    background-color: #0c6aa9;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* Box Shadow */
}

.attractive-button:hover {
    background-color: #2980b9;
    /* Darker Green */
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);
    /* Box Shadow on Hover */
}